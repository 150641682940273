import React from "react";
import styles from "./Style.module.css";
const HREscalationMatrixView = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logoImg}>
        <img
          src={require("../../assets/img/login logo@2x.png")}
       
          height={80}
          alt=""
        />
      </div>
      <div className={styles.loginSignupText}>
        <h1 className={styles.headingText}>HR Escalation Matrix</h1>
        <div className={styles.newLine} />
      </div>
<div className={styles.tableContainer}>

      <table className={styles.myTable}>
        <thead>
          <tr className={styles.centerText}>
            <th colSpan="4">Escalation Level I/II</th>
          </tr>
        </thead>

        <tbody>
          <tr className={styles.tableSubHeading}>
            <td>If you are joining at</td>
            <td>Name</td>
            <td>Contact</td>
            <td>Email Id</td>
          </tr>
          <tr className={styles.bgColorRow1}>
            <td>For ISL GBU</td>
            <td>Subhash Saini</td>
            <td>9992350193</td>
            <td>subhash.saini@indswiftlabs.com</td>
          </tr>
          <tr className={styles.grayRow}>
            <td>For ISL Samba</td>
            <td>Rajesh Kumar</td>
            <td>9875924231</td>
            <td>hr.ho@indswiftlabs.com</td>
          </tr>
          <tr className={styles.bgColorRow1}>
            <td>For ISLL & ISL Head Office</td>
            <td>Jyotsana Bekta</td>
            <td>9915109077</td>
            <td>hrbp.ho@indswiftlabs.com</td>
          </tr>
          <tr className={styles.grayRow}>
            <td>For other Locations</td>
            <td>Jyotsana Bekta</td>
            <td>9915109077</td>
            <td>hrbp.ho@indswiftlabs.com</td>
          </tr>
        </tbody>
      </table>
      <br />
     
      <table className={styles.myTable}>
        <thead>
          <tr className={styles.centerText}>
            <th colSpan="4">Escalation Level II/II</th>
          </tr>
        </thead>

        <tbody>
          <tr className={styles.tableSubHeading}>
            <td>If you are joining at</td>
            <td>Name</td>
            <td>Contact</td>
            <td>Email Id</td>
          </tr>
          <tr className={styles.bgColorRow1}>
            <td>Corporate Lead HR</td>
            <td>Akashdeep Sharma</td>
            <td>9915752168</td>
            <td>akashdeep.sharma@indswiftlabs.com</td>
          </tr>
        </tbody>
      </table>
      <br />
   
</div>
    </div>
  );
};

export default HREscalationMatrixView;
