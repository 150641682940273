import React, { useCallback, useMemo } from "react";
import { IconButton, MenuItem, ButtonBase } from "@material-ui/core";
import PageBox from "../../components/PageBox/PageBox.component";
import styles from "./Style.module.css";
import CustomSelectField from "../../components/FormFields/SelectField/SelectField.component";
import useApprovalRoute from "./ApprovalRoute.hook";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#1976d2",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#1976d2",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles({
  icon: {
    color: "#1976d2",
  },
  stepLabel: {
    fontWeight: 600,
  },
  stepInfo: {
    textAlign: "center",
  },
});

const CustomStepIcon = () => {
  const classes = useStyles();
  return <PersonIcon className={classes.icon} />;
};

const ApprovalRoute = ({}) => {
  const { listData, type, setType, locationId, setLocationId, data } =
    useApprovalRoute({});
  const classes = useStyles();

  const renderLocation = useMemo(() => {
    return (
      <CustomSelectField
        label={"Location"}
        value={locationId}
        handleChange={(value) => {
          setLocationId(value);
        }}
      >
        {listData?.LOCATIONS?.map((dT) => {
          return (
            <MenuItem value={dT?.id} key={dT?.id}>
              {dT?.name}
            </MenuItem>
          );
        })}
      </CustomSelectField>
    );
  }, [listData?.LOCATIONS, locationId]);

  const renderDropDownType = useMemo(() => {
    return (
      <CustomSelectField
        label={"Employee Type"}
        value={type}
        handleChange={(value) => {
          setType(value);
        }}
      >
        <MenuItem value={"ALL"}>ALL</MenuItem>
        <MenuItem value={"MARRAIGE"}>MARRAIGE</MenuItem>
        <MenuItem value={"CAR"}>CAR</MenuItem>
        <MenuItem value={"MOBILE"}>MARRAIGE</MenuItem>
        <MenuItem value={"PHC"}>PHC</MenuItem>
        <MenuItem value={"LOCAL_TRAVEL"}>LOCAL_TRAVEL</MenuItem>
        <MenuItem value={"INTERVIEW"}>INTERVIEW</MenuItem>
        <MenuItem value={"RELOCATION"}>RELOCATION</MenuItem>
        <MenuItem value={"TRAVEL"}>TRAVEL</MenuItem>
        <MenuItem value={"FOREIGN_TRAVEL"}>FOREIGN_TRAVEL</MenuItem>
        <MenuItem value={"MISCELLANEOUS"}>MISCELLANEOUS</MenuItem>
        <MenuItem value={"TAX_REBATE"}>TAX_REBATE</MenuItem>
        <MenuItem value={"LOAN"}>LOAN</MenuItem>
        <MenuItem value={"TRAVEL_PLANNER"}>TRAVEL_PLANNER</MenuItem>
        <MenuItem value={"IMPREST_TRAVEL_FOREIGN"}>
          IMPREST_TRAVEL_FOREIGN
        </MenuItem>
        <MenuItem value={"IMPREST_TRAVEL_DOMESTIC"}>
          IMPREST_TRAVEL_DOMESTIC
        </MenuItem>
        <MenuItem value={"IMPREST_OTHER"}>IMPREST_OTHER</MenuItem>
      </CustomSelectField>
    );
  }, [type, setType]);

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <div>
            <span className={styles.title}>Approval Routes</span>
            <div className={styles.newLine} />
          </div>
          <div className={styles.rightFlex}>
            {/* <ButtonBase className={styles.download}>
              DOWNLOAD TEMPLATE
            </ButtonBase> */}
          </div>
        </div>

        <div className={styles.yearFlex}>
          <div className={styles.drop}>{renderLocation}</div>
          <div className={styles.down}>{renderDropDownType}</div>
        </div>
      </PageBox>
      {/* <PageBox>
        <Stepper alternativeLabel connector={<CustomConnector />}>
          {data?.map((label, index) =>
            data[label]?.map((step) => (
              <Step key={index}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <div className={classes.stepInfo}>
                    <Typography variant="body1" className={classes.stepLabel}>
                      {step.label}
                    </Typography>
                    <Typography variant="body2">{step.name}</Typography>
                    <Typography variant="caption">{step.id}</Typography>
                  </div>
                </StepLabel>
              </Step>
            ))
          )}
        </Stepper>
      </PageBox> */}
    </div>
  );
};

export default ApprovalRoute;
